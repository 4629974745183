<template>
  <Breadcrumbs :items="breadcrumbItems" />
  <v-row>
    <v-col cols="12" class="d-flex justify-end">
      <!-- <v-btn disabled color="primary" @click="navigateToForm('add')">{{ $t('button.newSellIn') }}</v-btn> -->
      <!-- <v-btn class="ml-2" color="primary" @click="navigateToForm('import')">Import</v-btn> -->
    </v-col>
  </v-row>
  <v-row>
    <v-col cols="12">
      <data-table :headers="headers" :items="inboundSapData" :loading="loading"/>
    </v-col>
  </v-row>
  <v-overlay
    v-if="loading"
    :model-value="loading"
    class="align-center justify-center"
    persistent
  >
    <v-progress-circular
      color="primary"
      size="120"
      indeterminate
    ></v-progress-circular>
  </v-overlay>
</template>

<script>
import axios from '../plugins/axios';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import breadcrumbConfig from '@/others/breadcrumbConfig';
import { mapActions } from 'vuex';
import DataTable from '@/components/DataTable.vue';

export default {
  components: {
    Breadcrumbs,
    DataTable
  },
  data() {
    return {
      headers: [
        { title: 'dchl', key: 'dchl', align: 'start'},
        { title: 'sold_to_pt', key: 'sold_to_pt', align: 'start'},
        { title: 'name_sold_to_pt', key: 'name_sold_to_pt', align: 'start'},
        { title: 'ship_to_pt', key: 'ship_to_pt', align: 'start'},
        { title: 'name_ship_to_pt', key: 'name_ship_to_pt', align: 'start'},
        { title: 'region', key: 'region', align: 'start'},
        { title: 'city', key: 'city', align: 'start'},
        { title: 'req_dlv_date', key: 'req_dlv_date', align: 'end'},
        { title: 'contract_no', key: 'contract_no', align: 'start'},
        { title: 'contract_entry', key: 'contract_entry', align: 'start'},
        { title: 'contract_time', key: 'contract_time', align: 'end'},
        { title: 'purchase_order', key: 'purchase_order', align: 'end'},
        { title: 'po_date', key: 'po_date', align: 'end'},
        { title: 'valid_from', key: 'valid_from', align: 'end'},
        { title: 'valid_to', key: 'valid_to', align: 'end'},
        { title: 'contract_item', key: 'contract_item', align: 'start'},
        { title: 'material_code', key: 'material_code', align: 'start'},
        { title: 'description', key: 'description', align: 'start'},
        { title: 'contract_qty', key: 'contract_qty', align: 'end'},
        { title: 'contract_uom', key: 'contract_uom', align: 'start'},
        { title: 'net_price_cmo', key: 'net_price_cmo', align: 'start'},
        { title: 'currency', key: 'currency', align: 'start'},
        { title: 'sales_order', key: 'sales_order', align: 'start'},
        { title: 'so_entry', key: 'so_entry', align: 'end'},
        { title: 'so_time', key: 'so_time', align: 'end'},
        { title: 'so_item', key: 'so_item', align: 'start'},
        { title: 'so_qty', key: 'so_qty', align: 'end'},
        { title: 'rejection', key: 'rejection', align: 'end'},
        { title: 'desc_rejection', key: 'desc_rejection', align: 'start'},
        { title: 'delivery_no', key: 'delivery_no', align: 'start'},
        { title: 'do_item', key: 'do_item', align: 'start'},
        { title: 'delivery_qty', key: 'delivery_qty', align: 'start'},
        { title: 'actual_gi_date', key: 'actual_gi_date', align: 'end'},
        { title: 'pod_qty_diff', key: 'pod_qty_diff', align: 'start'},
        { title: 'delv_pod_diff', key: 'delv_pod_diff', align: 'start'},
        { title: 'received_at', key: 'synced_at', align: 'end'},
      ],
      inboundSapData: [],
      loading: false,
    };
  },
  computed: {
    breadcrumbItems() {
      const route = this.$route.name;
      const action = this.$route.params.action;
      const breadcrumbItems = breadcrumbConfig[route];

      // Handle case where the route is dynamic, e.g., 'sellIn-form'
      if (typeof breadcrumbItems === 'function') {
        return breadcrumbItems(action);
      }

      return breadcrumbItems || [];
    }
  },
  methods: {
    ...mapActions(['showAlert']),
    async fetchSyncedInboundSapData() {
      this.loading = true;
      try {
        const response = await axios.get('/interfacing/fetch_synced_inbound_sap_data');
        this.inboundSapData = response.data;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        const errorMessage = error.response?.data?.messages 
                            ? error.response.data.messages 
                            : error.response?.message || 'An error occurred';

        this.showAlert({ message: errorMessage, color: 'error' });
      }
    }
  },
  mounted() {
    this.fetchSyncedInboundSapData();
  }
};
</script>

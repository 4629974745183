<template>
  <Breadcrumbs :items="breadcrumbItems" />
  <v-row>
    <v-col cols="12" class="d-flex justify-end">
      <!-- <v-btn disabled color="primary" @click="navigateToForm('add')">{{ $t('button.newSellIn') }}</v-btn> -->
      <!-- <v-btn class="ml-2" color="primary" @click="navigateToForm('import')">Import</v-btn> -->
    </v-col>
  </v-row>
  <v-row>
    <v-col cols="12">
      <data-table :headers="headers" :items="outboundSapData" :loading="loading"/>
    </v-col>
  </v-row>
  <v-overlay
    v-if="loading"
    :model-value="loading"
    class="align-center justify-center"
    persistent
  >
    <v-progress-circular
      color="primary"
      size="120"
      indeterminate
    ></v-progress-circular>
  </v-overlay>
</template>

<script>
import axios from '../plugins/axios';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import breadcrumbConfig from '@/others/breadcrumbConfig';
import { mapActions } from 'vuex';
import DataTable from '@/components/DataTable.vue';

export default {
  components: {
    Breadcrumbs,
    DataTable
  },
  data() {
    return {
      headers: [
        { title: 'PACKNO', key: 'PACKNO', align: 'start'},
        { title: 'ORDER_TYPE', key: 'ORDER_TYPE', align: 'start'},
        { title: 'SALES_ORG', key: 'SALES_ORG', align: 'start'},
        { title: 'DISTR_CHANNEL', key: 'DISTR_CHANNEL', align: 'start'},
        { title: 'DIVISION', key: 'DIVISION', align: 'start'},
        { title: 'SOLD_TO_PARTY', key: 'SOLD_TO_PARTY', align: 'start'},
        { title: 'SHIP_TO_PARTY', key: 'SHIP_TO_PARTY', align: 'start'},
        { title: 'PO_NUMBER', key: 'PO_NUMBER', align: 'start'},
        { title: 'PO_DATE', key: 'PO_DATE', align: 'start'},
        { title: 'VALID_FROM', key: 'VALID_FROM', align: 'start'},
        { title: 'VALID_TO', key: 'VALID_TO', align: 'start'},
        { title: 'ITEM', key: 'ITEM', align: 'end'},
        { title: 'MATERIAL', key: 'MATERIAL', align: 'start'},
        { title: 'QUANTITY', key: 'QUANTITY', align: 'end'},
        { title: 'UNIT', key: 'UNIT', align: 'start'},
        { title: 'PLANT', key: 'PLANT', align: 'start'},
        { title: 'REQ_DELIV_DATE', key: 'REQ_DELIV_DATE', align: 'start'},
        { title: 'TRANS_CODE', key: 'TRANS_CODE', align: 'start'},
        { title: 'ADD_SINGLE', key: 'ADD_SINGLE', align: 'start'},
        { title: 'BATCH_NUMBER', key: 'BATCH_NUMBER', align: 'start'},
        { title: 'SYNCED_AT', key: 'SYNCED_AT', align: 'start'},
      ],
      outboundSapData: [],
      loading: false,
    };
  },
  computed: {
    breadcrumbItems() {
      const route = this.$route.name;
      const action = this.$route.params.action;
      const breadcrumbItems = breadcrumbConfig[route];

      // Handle case where the route is dynamic, e.g., 'sellIn-form'
      if (typeof breadcrumbItems === 'function') {
        return breadcrumbItems(action);
      }

      return breadcrumbItems || [];
    }
  },
  methods: {
    ...mapActions(['showAlert']),
    async fetchSyncedToSapData() {
      this.loading = true;
      try {
        const response = await axios.get('/interfacing/fetch_synced_to_sap_data');
        this.outboundSapData = response.data;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        const errorMessage = error.response?.data?.messages 
                            ? error.response.data.messages 
                            : error.response?.message || 'An error occurred';

        this.showAlert({ message: errorMessage, color: 'error' });
      }
    }
  },
  mounted() {
    this.fetchSyncedToSapData();
  }
};
</script>
